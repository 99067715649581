export interface IDialog {
    title: string
    body: string
    type: DialogTypes
    textConfirm?: string,
    textCancel?: string,
    onConfirm?: any,
    onCancel?: any
}

export enum DialogTypes {
    OK = 1,
    CONFIRM = 2,
    LOCK = 3
}