
import {computed, defineComponent, onBeforeMount, onMounted, ref, watch} from 'vue';
import {UserRoles} from "@/models/user.model";
import AlertViewer from "@/components/alerts/AlertViewer.vue";
import DialogConfirm from "@/components/DialogViewer.vue";
import {useAuthStore} from "@/store/auth";
import {storeToRefs} from "pinia";
import {useRoute, useRouter} from "vue-router";
import {useAlertStore} from "@/store/alert";
import AdminCockpit from "@/components/admin/admin_cockpit/AdminCockpit.vue";
import {useDialogStore} from "@/store/dialog";
import {DialogTypes} from "@/interfaces/dialog";
import ButtonRegular from "@/components/buttons/ButtonRegular.vue";
import {VOffline} from 'v-offline';
import {AppConfigurator} from "@/configuration/appConfigurator";

export default defineComponent({
  name: 'App',
  components: {
    ButtonRegular,
    AlertViewer,
    DialogConfirm,
    AdminCockpit,
    VOffline
  },
  setup() {
    const authStore = useAuthStore();
    const alertStore = useAlertStore();
    const dialogStore = useDialogStore();
    const router = useRouter();
    const route = useRoute();
    const {user} = storeToRefs(authStore);

    const online = ref(false);

    const displayLogoutPrompt = computed(() => {
      return user.value.role === UserRoles.ADMININISTRATOR && screen.width <= 760;
    })

    function logout() {
      authStore.logout();
      router.push({name: "login"});
    }

    function onNetworkChange(val: boolean) {
      if (!val) {
        dialogStore.currentDialog! = {
          type: DialogTypes.LOCK,
          title: "Geen internet",
          body: "Het lijkt erop dat je geen internettoegang hebt."
        }
      } else if (!online.value) {
        dialogStore.currentDialog = null;
      }
      online.value = val;
    }

    watch(() => user.value.username, () => {
      document.title = "Ritstapp - Inloggen";
      if (authStore.isAuthenticatedLocal()) {
        document.title = `Ritstapp - ${user.value.companyName}`;
      }
    })

    /* Clear alerts on route change. */
    watch(route, () => {
      alertStore.clearAlerts();
    })

    onBeforeMount(() => {
      document.title = "Ritstapp - Inloggen";

      const appConfigurator = AppConfigurator.getInstance();
      appConfigurator.applyConfiguration();
    })

    return {user, authStore, displayLogoutPrompt, onNetworkChange, logout, UserRoles}
  },
})
