import axios from 'axios'
import localStorageService from "@/services/localStorageService";
import router from "@/router";

const axiosBasic = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_BASE_URL
})

const axiosAdvanced = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_BASE_URL
})

// Add a request interceptor
axiosAdvanced.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        if (token && config.headers) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });


axiosAdvanced.interceptors.response.use((response) => {
        return response
    },
    function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = localStorageService.getRefreshToken();
            if (refreshToken) {
                return axiosBasic.post('/auth/token/refresh/',
                    {
                        "refresh": refreshToken
                    })
                    .then(res => { // Refresh token accepted. Set new access token.
                        if (res && res.status === 200) {
                            // 1) put token to LocalStorage
                            localStorageService.setAccessToken(res.data.access);

                            // 2) Change Authorization header
                            axiosAdvanced.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();

                            // 3) return originalRequest object with Axios.
                            return axiosAdvanced(originalRequest);
                        }
                    })
                    .catch(res => { // Refresh token expired. Back to login.
                        router.replace({ name: "login" });
                    });

            }
        }

        // return Error object with Promise
        return Promise.reject(error);
    });

export default axiosAdvanced;