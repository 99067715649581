import {AppThemeConfigurator} from "@/configuration/appThemeConfigurator";
import {IAppConfig} from "@/interfaces/appConfig.interface";
import defaultAppConfig from "@/configuration/defaultAppConfig";
import * as _ from 'lodash';

export class AppConfigurator {

    private static instance: AppConfigurator;

    private appConfig: IAppConfig;

    private appThemeConfigurator: AppThemeConfigurator;

    private constructor() {
        this.appConfig = _.cloneDeep(defaultAppConfig);
        this.appThemeConfigurator = new AppThemeConfigurator();
    }

    public static getInstance() {
        if (!this.instance) {
            this.instance = new AppConfigurator();
        }
        return this.instance;
    }

    public assignAppConfig(appConfig: IAppConfig) {
        this.appConfig = _.cloneDeep(defaultAppConfig);
        this.appConfig = _.merge(this.appConfig, appConfig);
    }

    public getAppConfig() {
        return this.appConfig;
    }

    public applyConfiguration() {
        this.appThemeConfigurator.applyTheme(this.appConfig);
    }

}