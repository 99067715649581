
import {computed, defineComponent} from "vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,} from '@headlessui/vue';
import {useDialogStore} from "@/store/dialog";
import {storeToRefs} from "pinia";
import {DialogTypes} from "@/interfaces/dialog";

export default defineComponent({
  name: "DialogViewer",
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  setup() {
    const dialogStore = useDialogStore();
    const {currentDialog} = storeToRefs(dialogStore);

    const isOpen = computed(() => {
      return !!currentDialog.value;
    });

    const defaultConfirmButtonTextMap = {
      [DialogTypes.OK]: "Ok",
      [DialogTypes.CONFIRM]: "Bevestigen",
      [DialogTypes.LOCK]: null
    }

    const confirmButtonText = computed(() => {
      if (currentDialog.value) {
        if (currentDialog.value.textConfirm) {
          return currentDialog.value.textConfirm;
        }
        return defaultConfirmButtonTextMap[currentDialog.value.type];
      }
      return null;
    });

    const cancelButtonText = computed(() => {
      if (currentDialog.value?.textCancel) {
        return currentDialog.value.textCancel;
      }
      return "Annuleren";
    });

    function onUserConfirm() {
      if (currentDialog.value?.onConfirm) {
        currentDialog.value.onConfirm();
      }
      currentDialog.value = null;
    }

    function onUserCancel() {
      if (currentDialog.value?.type === DialogTypes.LOCK) {
        return;
      }

      if (currentDialog.value?.onCancel) {
        currentDialog.value.onCancel();
      }
      currentDialog.value = null;
    }

    return {isOpen, currentDialog, confirmButtonText, cancelButtonText, onUserConfirm, onUserCancel, DialogTypes}
  }
});
