
import {computed, defineComponent, onUnmounted, watch} from "vue";
import {useAlertStore} from "@/store/alert";
import {storeToRefs} from "pinia";
import {AlertTypes} from "@/interfaces/alert";
import AlertViewerAlert from "@/components/alerts/AlertViewerAlert.vue";

export default defineComponent({
  name: "AlertViewer",
  components: {
    AlertViewerAlert
  },
  setup() {
    const alertLifeTime = 5000;
    const alertStore = useAlertStore();
    const {currentAlert, upcomingAlert, queuedAlerts} = storeToRefs(alertStore);

    const showAlert = computed(() => {
      return currentAlert && currentAlert.value !== null && currentAlert.value !== undefined && currentAlert?.value.text;
    })

    function closeCurrentAlert() {
      showNextAlert();
    }

    watch(queuedAlerts, () => {
      if (!currentAlert.value?.text) {
        showNextAlert();
      }
    }, {deep: true})

    function showNextAlert() {
      let lifetime = alertLifeTime;
      if (upcomingAlert.value?.lifetime) {
        lifetime = upcomingAlert.value.lifetime;
      }
      if (alertStore.nextAlert()) {
        setTimeout(() => {
          showNextAlert();
        }, lifetime);
      } else {
        currentAlert.value = null;
      }
    }

    return {currentAlert, showAlert, closeCurrentAlert, AlertTypes}
  }
})
