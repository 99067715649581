import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from 'pinia'
import '/src/assets/css/tailwind.css'
import {loadFonts} from './plugins/webfontloader'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import "./registerServiceWorker";



/* --- FONT-AWESOME -----> */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGasPump } from '@fortawesome/free-solid-svg-icons'
library.add(faGasPump)
/* <----- FONT-AWESOME --- */


loadFonts()

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://3ec49081dcd7423cbd1ec7bac651baba@o4504156663250944.ingest.sentry.io/4504156676685824",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [],
        }),
    ],
    environment: process.env.NODE_ENV,
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
})

app.use(router)
    .component("FontAwesomeIcon", FontAwesomeIcon)
    .use(createPinia())
    .mount('#app')
