
import {computed, defineComponent} from "vue";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  ListBulletIcon, UserCircleIcon
} from "@heroicons/vue/24/solid";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import {
  ArrowLeftOnRectangleIcon,
  Bars3BottomLeftIcon,
  BellIcon, BuildingLibraryIcon, CalculatorIcon,
  CalendarIcon,
  ChartBarIcon, ClockIcon, CurrencyEuroIcon,
  FolderIcon, HomeIcon, InboxIcon, MagnifyingGlassIcon,
  TruckIcon, XMarkIcon
} from "@heroicons/vue/24/outline";

export default defineComponent({
  name: "AdminCockpitNavigationListItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, Menu, MenuButton, MenuItem, MenuItems,
    TruckIcon, Bars3BottomLeftIcon, BellIcon, CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, ClockIcon,
    XMarkIcon, BuildingLibraryIcon, ArrowLeftOnRectangleIcon, MagnifyingGlassIcon, CurrencyEuroIcon, CalculatorIcon,
    ListBulletIcon, UserCircleIcon,
    Dialog, DialogPanel, TransitionChild, TransitionRoot,
  },
  emits: ["update:modelValue", "item-clicked"],
  setup(props, {emit}) {
    const innerItem = computed({
      get() {
        return props.item;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    })
    
    return { innerItem }
  }
})
