
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {
  ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon, ListBulletIcon, UserCircleIcon
} from "@heroicons/vue/24/solid";
import {
  Dialog,
  DialogPanel,
  Disclosure, DisclosureButton, DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import {useAuthStore} from "@/store/auth";
import {useUserStore} from "@/store/user";
import {
  ArrowLeftOnRectangleIcon,
  Bars3BottomLeftIcon,
  BellIcon,
  BuildingLibraryIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  ClockIcon,
  XMarkIcon,
  TruckIcon,
  CalculatorIcon,
  CurrencyEuroIcon
} from "@heroicons/vue/24/outline";
import {useRoute, useRouter} from "vue-router";
import {faGasPump} from "@fortawesome/free-solid-svg-icons";
import AdminCockpitNavigationListItem from "@/components/admin/admin_cockpit/AdminCockpitNavigationListItem.vue";
import {storeToRefs} from "pinia";
import {AppConfigurator} from "@/configuration/appConfigurator";

export default defineComponent({
  name: "AdminCockpit",
  components: {
    AdminCockpitNavigationListItem,
    ArrowLeftOnRectangleIcon, UserCircleIcon
  },
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const { user } = storeToRefs(authStore);
    const router = useRouter();
    const route = useRoute();

    const sidebarOpen = ref(true)

    const navigation = reactive([
      {
        name: "Werkzaamheden", routeName: "admin-activities-driver", icon: TruckIcon, current: computed(() => {
          return route.name === "admin-activities-driver"
        })
      },
      {
        name: "Tankstaten", routeName: "admin-refuel-activities-driver", faIcon: "fa-solid fa-gas-pump", current: computed(() => {
          return route.name === "admin-refuel-activities-driver"
        })
      },
      {
        name: "Administratie", icon: CalculatorIcon, open: false,
        children: [
          {
            name: "Overzicht", routeName: "drivers-administration-overview", icon: ListBulletIcon,
            current: computed(() => {
              return route.name === "drivers-administration-overview"
            })
          },
          {
            name: "Uren", routeName: "drivers-hours-week", icon: ClockIcon,
            current: computed(() => {
              return route.name === "drivers-hours-week"
            })
          },
          {
            name: "Verblijfskosten", routeName: "drivers-subsistence-allowance-week", icon: CurrencyEuroIcon,
            current: computed(() => {
              return route.name === "drivers-subsistence-allowance-week"
            })
          },
          {
            name: "Reiskosten", routeName: "drivers-travel-compensation-week", icon: CurrencyEuroIcon,
            current: computed(() => {
              return route.name === "drivers-travel-compensation-week"
            })
          },
        ]
      },
      {
        name: "Beheer",
        href: process.env.VUE_APP_ENTITY_MANAGEMENT,
        icon: BuildingLibraryIcon,
        current: false
      },
    ])

    const userNavigation = [
      {name: "Sign out", href: "#"},
    ]

    const adminLogoConfig = computed(() => {
      const appConfigurator = AppConfigurator.getInstance();
      return appConfigurator.getAppConfig().appearance.adminLogo;
    })

    function onMenuItemClick(item: any) {
      if (item.href) {
        window.open(item.href, "_blank");
      }
      else {
        router.push({name: item.routeName});
      }
    }

    function logout() {
      authStore.logout();
      router.replace({name: "login"});
    }

    onMounted(() => {
      userStore.fetchDrivers();
    })

    return {
      navigation, userNavigation, sidebarOpen, user, adminLogoConfig,
      onMenuItemClick, logout
    }
  }
})
